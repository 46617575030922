import React from "react";
import SilverCard from "@/assets/images/card_silver.png";
// import BlueCard from '@/assets/images/card_blue.png';
// import GreenCard from '@/assets/images/card_green.png';
import { ReactComponent as NTF } from "@/assets/images/NTF.svg";
import { FigureType } from "@/utils/constants/constants";

export interface CommonProps {
  onClick: () => void;
  type?: FigureType;
  minted?: number;
}

const Subscription = ({ onClick }: CommonProps) => {
  return (
    <div className="subcription">
      <div className="card__grid big_gap">
        <div className="card__item">
          <div className="card_image">
            <img src={SilverCard} alt="SilverCard" />
          </div>
          <div className="card_content">
            <h3 className="title normal">Grey Membership Pass</h3>
            <p className="money gray">0.2 Bnb</p>
            <p>Miễn phí 1 tháng membership</p>
            <p> Voucher 10%</p>
            <p> Future Airdrop</p>
            <button className={`multi_color_btn button '}`} onClick={onClick}>
              <NTF />
              Mint now
            </button>
          </div>
        </div>
        {/* <div className='card__item'> 
              <div className='card_image'>
                <img src={BlueCard} alt="BlueCard" />
              </div>
              <div className='card_content'> 
                <h3 className='title normal'>
                Grey Membership Pass
                </h3>
                <p className='money blue'>39$</p>
                <p>Miễn phí 1 tháng membership</p>
                <p> Voucher 10%</p>
                <p> Future Airdrop</p>  
                <TokenButton changeLoading={setLoading}/>       
              </div>             
            </div> 
            <div className='card__item'> 
              <div className='card_image'>
                <img src={GreenCard} alt="GreenCard" />
              </div>
              <div className='card_content'> 
                <h3 className='title normal'>
                Grey Membership Pass
                </h3>
                <p className='money green'>39$</p>
                <p>Miễn phí 1 tháng membership</p>
                <p> Voucher 10%</p>
                <p> Future Airdrop</p>
                <TokenButton  changeLoading={setLoading}/>     
              </div>             
            </div>    
         */}
      </div>
    </div>
  );
};

export default Subscription;
